import React, { useState, useEffect } from "react";
import RegistryTable from "./components/RegistryTable";

const Registry = () => {
  const [entries, setEntries] = useState([]);
  useEffect(()=>{
    fetch('https://MCarolan.pythonanywhere.com/registry',{
      'methods':'GET',
        headers : {
          'Content-Type':'application/json',
        }
      })
    .then(response => response.json())
    .then(response => setEntries(response))
    .catch(error => console.log(error))
  },[])

  return (
    <div className="registry">
      <div className="instructions">
        <p>
          Please address gifts to:
        </p>
      </div>
      <div className="instructions">
        <p>
          <em>Kristen & Michael Carolan<br></br></em>
          <em>2451 Brickell Avenue, Apt 11L<br></br></em>
          <em>Miami, FL 33129<br></br></em>
        </p>
      </div>
      <div className="instructions">
        <p>
          We have compiled a list of gifts that we would absolutely <i>love</i> to put in our
          new home as we start our life together.
        </p>
        <p>
        If you would like to give us one of these items as a wedding gift, click on the item, 
        and you will be taken to the store where you can purchase it (we tried to mention if we 
        like certain colors or models). After that, click the "Claim" button next to the item
        below so that everyone knows it’s gone. We would love a pizza oven, but we don’t 
        need two!
        </p>
        <p>
        If you claim an item and change your mind later, please come back and "Unclaim" the item
        by clicking the rightmost button again. You will have to enter the secret code you
        got when you first claimed the item to unclaim it, so please make sure you write it down
        when you claim something.
        </p>
        <p>
          (Of course, if you have found the <i>perfect</i> gift that is not on this list, we'll
          be just as excited to open it!)
        </p>
      </div>
      <RegistryTable entries={entries} setEntries={setEntries} />
    </div>
  )
};

export default Registry;