import React, { useState } from "react";
import '../App.scss'
import ConfirmClaim from "./ConfirmClaim";
import ClaimConfirmed from "./ClaimConfirmed";
import ConfirmUnclaim from "./ConfirmUnclaim";
import Result from "./Result";

const RegistryTable = (props) => {
  const entries = props.entries;
  const setEntries = props.setEntries;
  const [state, setState] = useState({ 
    entryIndex:-1,
    modalName:'',
    modal:(<div/>)
  });
  const [unclaimHover, setUnclaimHover] = useState({
    hovering: false,
    index: null
  });

  const handleClick = (i, claimed) => {
    return claimed ? () => {
      setState({
        modalName: 'confirmUnclaim',
        entryIndex: i,
        modal: getModal('confirmUnclaim', i),
      });
    } : () => {
      setState({
        modalName: 'confirmClaim',
        entryIndex: i,
        modal: getModal('confirmClaim', i),
      });
    }
  };

  const handleClose = () => { // used to close out modals
    setState({
      entryIndex:-1,
      modalName:'',
      modal:(<div/>)
    });
  };

  const handleYes = (i) => async () => {
    const changedEntry = entries[i];
    changedEntry.claimed = !changedEntry.claimed;
    entries[i] = changedEntry;
    fetch('https://MCarolan.pythonanywhere.com/registry/claim',{
      method:'POST',
      mode: 'cors',
      headers : {
        'Content-Type':'application/json',
      },
      body: JSON.stringify({entry:changedEntry.code})
    })
    .then(response => response.json())
    .then(response => setEntries(response))
    .catch(error => console.log(error));
    setState({
      entryIndex: -1,
      modalName: 'claimConfirmed',
      modal: getModal('claimConfirmed', i)
    });
  }

  const handleSubmit = (code, i) => (attempt) => {
    if (attempt === code) {
      const changedEntry = entries[i];
      changedEntry.claimed = !changedEntry.claimed;
      entries[i] = changedEntry;
      fetch('https://MCarolan.pythonanywhere.com/registry/unclaim',{
        method:'POST',
        mode: 'cors',
        headers : {
          'Content-Type':'application/json',
      },
        body: JSON.stringify({entry:changedEntry.code})
      })
    .then(response => response.json())
    .then(response => setEntries(response))
    .catch(error => console.log(error));
      setState({
        entryIndex: -1,
        modalName: 'unclaimConfirmed',
        modal: getModal('unclaimConfirmed', i)
      });
    } else {
      setState({
        entryIndex: -1,
        modalName: 'failedUnclaim',
        modal: getModal('failedUnclaim', i)
      });
    }
  }

  const handleRetry = (i) => () => {
    setState({
      entryIndex: i,
      modalName: 'confirmUnclaim',
      modal: getModal('confirmUnclaim', i),
    });
  };

  function getModal(name, i) {
    switch (name) {
      case 'confirmClaim':
        return (
          <ConfirmClaim giftName={entries[i].name} handleYes={handleYes(i)} handleClose={handleClose}/>
        );
      case 'claimConfirmed':
        return (
          <ClaimConfirmed code={entries[i].code} handleClose={handleClose}/>
        );
      case 'confirmUnclaim':
        return (
          <ConfirmUnclaim handleSubmit={handleSubmit(entries[i].code, i)} handleClose={handleClose}/>
        );
      case 'unclaimConfirmed':
        return (
          <Result words="You have successfully unclaimed this gift! We hope you'll pick a new one!"
          handleClose={handleClose}/>
        );
      case 'failedUnclaim':
        return (
          <Result words="That wasn't the right code. Do you want to try again? Maybe you're meant to get this gift..."
          handleRetry={handleRetry(i)} handleClose={handleClose}/>
        );
      default:
    }
  };
  return (
    <div className="registryTable">
      {entries.map((entry, i) => {
        return (
          <div className='registryRow' key={i}>
            <div className={'regEntry ' + (entry.claimed ? "claimed" : "available")}>
              <a href={entry.claimed ? null : entry.link}>{entry.name}</a>
            </div>
            <div className={'regEntry money ' + (entry.claimed ? "claimed" : "available")}>
              {entry.price}
            </div>
            <div 
              className={'regEntry noselect button ' + (entry.claimed ? "claimed" : "available")}
              onMouseEnter={() => setUnclaimHover({hovering: true, index: i})}
              onMouseLeave={() => setUnclaimHover({hovering: false, index: null})}
              >
                <div onClick={handleClick(i, entry.claimed)}>{
                  entry.claimed ? (unclaimHover.hovering && i === unclaimHover.index ? "Unclaim" : "Claimed") : "Claim"
                  }</div>
            </div>
          </div>
        )
        }
      )}
      {state.modal}
    </div>
  )
};

export default RegistryTable;