import React, { Component } from 'react';
import {
    NavLink,
  } from "react-router-dom";
import '../App.scss';
import { ReactComponent as Heart } from './iconmonstr-favorite-3.svg';

class NavBarButton extends Component {
    render() {
        return (
            <div className='nav-button-wrapper'>
                <div className='heart-triggerer'>
                    <NavLink to={this.props.to} className={this.props.className}>
                        {this.props.text}
                    </NavLink>
                </div>
                <Heart className={'heart' + (this.props.text.length >= 9 ? ' B' : ' A')}/>
                <Heart className={'heart2' + (this.props.text.length >= 9 ? ' B' : ' A')}/>
            </div>
        )
    }
}

export default NavBarButton;