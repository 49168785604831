import React from 'react';
import Close from './Close';
import '../App.scss';

function ConfirmClaim(props) {
    return (
        <div className='blackout'>
            <div className='modal'>
                <div className='modal-inner'>
                    Are you sure you want to claim the <strong>{props.giftName}</strong> as your gift?
                    <div className="yes noselect" onClick={props.handleYes}>Yes</div>
                    <Close handleClose={props.handleClose}/>
                </div>
            </div>
        </div>
    )
}

export default ConfirmClaim;