import React, { useState } from "react";
import Madeline from './bridesmaids/Maddy.png';
import Chiara from './bridesmaids/Chiara.png';
import Dana from './bridesmaids/Dana.png';
import Erin from './bridesmaids/Erin.png';
import Kaitlyn from './bridesmaids/Kaitlyn.png';
import Katie from './bridesmaids/Katie.png';
import Reagan from './bridesmaids/Reagan.png';
import Rory from './bridesmaids/Rory.png';
import Stephanie from './bridesmaids/Stephanie.png'
import Carolyn from './bridesmaids/Carolyn.png'
import Madeline2 from './bridesmaids/Maddy2.png';
import Chiara2 from './bridesmaids/Chiara2.png';
import Dana2 from './bridesmaids/Dana2.png';
import Erin2 from './bridesmaids/Erin2.png';
import Kaitlyn2 from './bridesmaids/Kaitlyn2.png';
import Katie2 from './bridesmaids/Katie2.png';
import Reagan2 from './bridesmaids/Reagan2.png';
import Rory2 from './bridesmaids/Rory2.png';
import Stephanie2 from './bridesmaids/Stephanie2.png'
import Carolyn2 from './bridesmaids/Carolyn2.png'

const BrideSquad = () => {
    const [versions, setVersions] = useState(Array(10).fill(true));

    const getOnHover = (index) => () => {
        let newVersions = [...versions];
        newVersions[index] = !newVersions[index];
        setVersions(newVersions);
    };

    const pics = [Madeline,Chiara,Dana,Kaitlyn,Stephanie,Erin,Reagan,Rory,Katie,Carolyn];
    const names = ["Madeline", "Chiara", "Dana", "Kaitlyn", "Stephanie",
            "Erin", "Reagan", "Rory", "Katie", "Carolyn"];
    const funnyPics = [Madeline2,Chiara2,Dana2,Kaitlyn2,Stephanie2,Erin2,Reagan2,Rory2,Katie2,Carolyn2]
    const nicknames = ['Middle Child #2', 'Srat Star', 'Party Spice', 'Clueless', 'Sabrina the Middle-Aged Witch',
                'Play Girl', 'Sk8er Gurl', '"Patty"', '"ID Please"', "Big Red"];

    const bridesmaids0 = [0,1,2,3,4].map(function(i){
        return <div className="bridesmaid-block" onMouseOver={getOnHover(i)} onMouseOut={getOnHover(i)}>
            {(versions[i] ? <img src={pics[i]} alt={names[i]} className="bridesmaid-image"/> :
            <img src={funnyPics[i]} alt={nicknames[i]} className="bridesmaid-image"/>)}
            {(versions[i] ? <div className="bridesmaid-name noselect">{names[i]}</div> :
            <div className="bridesmaid-nickname noselect">{nicknames[i]}</div>)}
        </div>
    });
    const bridesmaids1 = [5,6,7,8,9].map(function(i){
        return <div className="bridesmaid-block" onMouseOver={getOnHover(i)} onMouseOut={getOnHover(i)}>
            {(versions[i] ? <img src={pics[i]} alt={names[i]} className="bridesmaid-image"/> :
            <img src={funnyPics[i]} alt={nicknames[i]} className="bridesmaid-image"/>)}
            {(versions[i] ? <div className="bridesmaid-name noselect">{names[i]}</div> :
            <div className="bridesmaid-nickname noselect">{nicknames[i]}</div>)}
        </div>
    });

    return (
            <div className="bride-squad">
                <div className="bridesmaid-col">
                    {bridesmaids0}
                </div>
                <div className="bridesmaid-col">
                    {bridesmaids1}
                </div>
            </div>
        )
}

export default BrideSquad;