import React, { useState, useEffect } from "react";
import RSVPForm from "./components/RSVPForm";

const RSVP = () => {

  return (
    <div>
      <div className="rsvpbox">
        <RSVPForm></RSVPForm>
      </div>
    </div>
  )
};

export default RSVP;