import NavBar from "./components/NavBar";
import Home from "./Home";
import Registry from "./Registry";
import BrideSquad from "./BrideSquad";
import GroomsDudes from "./GroomsDudes";
import RSVP from "./RSVP";
import {
  Route,
  Routes,
  HashRouter
} from "react-router-dom";
import './App.scss';

function App() {
  return (
    <div className="App">
      <HashRouter>
        <NavBar/>
        <div className="content">
          <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path="/registry" element={<Registry/>}/>
            <Route path="/bride-squad" element={<BrideSquad/>}/>
            <Route path="/grooms-dudes" element={<GroomsDudes/>}/>
            <Route path="/rsvp" element={<RSVP/>}/>
          </Routes>
        </div>
      </HashRouter>
      <div className="bottomText">Website and design © 2021 Michael Carolan</div>
    </div>
  );
}

export default App;
