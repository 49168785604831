import React from 'react';
import Close from './Close';
import '../App.scss';

function ClaimConfirmed(props) {
    return (
        <div className='blackout'>
            <div className='modal'>
                <div className='modal-inner padded'>
                        Awesome! Everyone will now see that this gift is claimed. If you decide to 
                        unclaim this gift, you will need this code:
                        <b>{props.code}</b>
                        Please write it down just in case!
                    <Close handleClose={props.handleClose}/>
                </div>
            </div>
        </div>
    )
}

export default ClaimConfirmed;