import React, { Component } from "react";
import MikeAndKristen from './components/MikeAndKristen.jpg';

class Home extends Component {
    render() {
        return (
            <div className="home">
                <div className="home-image-holder">
                    <img src={MikeAndKristen} alt="Mike and Kristen after getting engaged" 
                        className="home-image"/>
                    <div className="image-text noselect">April 15th</div>
                    <div className="image-text2 noselect">2023</div>
                </div>
            </div>
        )
    }
}

export default Home;