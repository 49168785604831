import React, { Component } from 'react';
import NavBarButton from './NavBarButton';
import '../App.scss';

class NavBar extends Component {
    render() {
        return (
            <div className="holder">
                <div className="intro noselect">Woohoo! We’re getting married!</div>
                <div className="title noselect">Michael and Kristen</div>
                <div className="line"/>
                <div className="bar">
                    <NavBarButton to="/" className="nav noselect" text="Home"/>
                    <NavBarButton to="/registry" className="nav noselect" text="Registry"/>
                    <NavBarButton to="/rsvp" className="nav noselect" text="RSVP"/>
                    <NavBarButton to="/bride-squad" className="nav noselect" text="Bride Squad"/>
                    <NavBarButton to="/grooms-dudes" className="nav noselect" text="Grooms Dudes"/>
                </div>
            </div>
        )
    }
}

export default NavBar;