import React, { useState } from 'react';
import Close from './Close';
import '../App.scss';

function ConfirmUnclaim(props) {
    const [textInput, setTextInput] = useState('');
      
    return (
        <div className='blackout'>
            <div className='modal'>
                <div className='modal-inner padded'>
                    This gift has been claimed. If you claimed this gift and you would like to unclaim it, 
                    please enter the code you were given when you claimed your gift: 
                    <input 
                    onChange={(event) => {setTextInput(event.target.value);}} 
                    placeholder="Secret Code..."
                    ></input>
                    <div className="yes noselect" onClick={() => props.handleSubmit(textInput)}>Submit</div>
                    <Close handleClose={props.handleClose}/>
                </div>
            </div>
        </div>
    )
}

export default ConfirmUnclaim;