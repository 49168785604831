import React from 'react';
import { ReactComponent as X } from './icons8-cancel.svg'
import '../App.scss';

function Close(props) {
    return (
        <div className='close'>
            <X className='x' onClick={props.handleClose}/>
        </div>
    )
}

export default Close;