import React, { useState } from "react";
import Alden from './groomsmen/Alden.png';
import Collin from './groomsmen/Collin.png';
import Jack from './groomsmen/Jack.png';
import John from './groomsmen/John.png';
import Jonathon from './groomsmen/Jonathon.png';
import Nick from './groomsmen/Nick.png';
import Quinn from './groomsmen/Quinn.png';
import Sal from './groomsmen/Sal.png';
import Zack from './groomsmen/Zack.png'
import Alden2 from './groomsmen/Alden2.png';
import Collin2 from './groomsmen/Collin2.png';
import Jack2 from './groomsmen/Jack2.png';
import John2 from './groomsmen/John2.png';
import Jonathon2 from './groomsmen/Jonathon2.png';
import Nick2 from './groomsmen/Nick2.png';
import Quinn2 from './groomsmen/Quinn2.png';
import Sal2 from './groomsmen/Sal2.png';
import Zack2 from './groomsmen/Zack2.png';
import Johnny from './groomsmen/Johnny.png';
import Johnny2 from './groomsmen/Johnny2.png';

const GroomsDudes = () => {
    const [versions, setVersions] = useState(Array(10).fill(true));

    const getOnHover = (index) => () => {
        let newVersions = [...versions];
        newVersions[index] = !newVersions[index];
        setVersions(newVersions);
    };

    const pics = [Collin,Alden,Jack,John,Jonathon,Zack,Nick,Quinn,Sal,Johnny];
    const names = ["Collin", "Alden", "Jack", "John", "Jonathon",
            "Zack", "Nick", "Quinn", "Sal", "Johnny"];
    const funnyPics = [Collin2,Alden2,Jack2,John2,Jonathon2,Zack2,Nick2,Quinn2,Sal2,Johnny2]
    const nicknames = ['The Roommate', 'All Hands on Deck', 'The Hangover Part 4', 'Bob Saget, Attorney at Law', 'Guido Unchained',
                'Danny California', 'Boy Scouter', 'Lil Shit', "Grandma's Favorite", 'The Seaman'];

    const groomsmen0 = [0,1,2,3,4].map(function(i){
        return <div className="bridesmaid-block" onMouseOver={getOnHover(i)} onMouseOut={getOnHover(i)}>
            {(versions[i] ? <img src={pics[i]} alt={names[i]} className="bridesmaid-image"/> :
            <img src={funnyPics[i]} alt={nicknames[i]} className="bridesmaid-image"/>)}
            {(versions[i] ? <div className="bridesmaid-name noselect">{names[i]}</div> :
            <div className="bridesmaid-nickname noselect">{nicknames[i]}</div>)}
        </div>
    });
    const groomsmen1 = [5,6,7,8,9].map(function(i){
        return <div className="bridesmaid-block" onMouseOver={getOnHover(i)} onMouseOut={getOnHover(i)}>
            {(versions[i] ? <img src={pics[i]} alt={names[i]} className="bridesmaid-image"/> :
            <img src={funnyPics[i]} alt={nicknames[i]} className="bridesmaid-image"/>)}
            {(versions[i] ? <div className="bridesmaid-name noselect">{names[i]}</div> :
            <div className="bridesmaid-nickname noselect">{nicknames[i]}</div>)}
        </div>
    });;

    return (
            <div className="bride-squad">
                <div className="bridesmaid-col">
                    {groomsmen0}
                </div>
                <div className="bridesmaid-col">
                    {groomsmen1}
                </div>
            </div>
        )
}

export default GroomsDudes;