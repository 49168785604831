import React from 'react';
import Close from './Close';
import '../App.scss';

function Result(props) {
    const showRetry = props.handleRetry ? 
        <div className="yes" onClick={props.handleRetry}>Retry</div> : null;
    return (
        <div className='blackout'>
            <div className='modal'>
                <div className='modal-inner padded'>
                    {props.words}
                    {showRetry}
                    <Close handleClose={props.handleClose}/>
                </div>
            </div>
        </div>
    )
}

export default Result;