import React, { useState } from "react";
import '../App.scss';
import Engagement from './Engagement.jpg';

const RSVPForm = () => {
  const [state, setState] = useState({ 
    code: '',
    step: 0,
    people: [],
    checkedPeople: []
  });
  const [textInput, setTextInput] = useState('');

  const handleSubmit1 = (code) => {
    fetch('https://MCarolan.pythonanywhere.com/rsvp',{
        method:'POST',
        mode: 'cors',
        headers : {
          'Content-Type':'application/json',
        },
        body: JSON.stringify(code)
      })
    .then(response => {
      if (!response.ok) {
        throw new Error("Your invitation code did not match any groups. Please try again, or contact Michael and Kristen.")
      }
      return response.json()
    })
    .then(responseJSON => {
        setState({
            code: code,
            step: state.step + 1,
            people: responseJSON,
            checkedPeople: new Array(responseJSON.length).fill(false)
        })
    })
    .catch(error => alert(error))
  };

  const handleSubmit2 = () => {
    setState({...state, step: state.step + 1});
  };

  const handleSubmit3 = () => {
    const yes = state.people.filter((person, i) => state.checkedPeople[i]);
    const no = state.people.filter((person, i) => !state.checkedPeople[i]);
    fetch('https://MCarolan.pythonanywhere.com/sendrsvp',{
        method:'POST',
        mode: 'cors',
        headers : {
          'Content-Type':'application/json',
        },
        body: JSON.stringify({code: state.code, yes, no})
      }).then(
        response => response.json())
        .then(response => alert(response))
    setState({...state, step: state.step + 1});
  };

  const handleNo = () => {
    setState({step: 0, people: [], checkedPeople: []});
  };

  const handleChange = (index) => {
    const checkedState = state.checkedPeople.map((item, position) => position === index ? !item : item);
    setState({...state, checkedPeople: checkedState});
  }

  return (
    <div className="rsvpForm">
      {state.step === 0 ? (
        <div className="step0">
            Please enter the RSVP code from your invitation (reach out to Kristen if you can not find it)
            <img src={Engagement} alt="Mike and Kristen in love" className="engagement-image"/>
            <form onSubmit={(event) => {
                    event.preventDefault();
                    handleSubmit1(textInput);
                }}>
                <input 
                    onChange={(event) => {setTextInput(event.target.value);}} 
                    placeholder="Secret Code..."
                    className="secret-input"
                    ></input>
                <input type="submit" style={{display: 'none'}}></input>
                <div className="submit noselect" onClick={() => handleSubmit1(textInput)}>Submit</div>
            </form>
        </div>
      ) : state.step === 1 ? (
        <div className="step1">
            Is this the party that you are RSVPing for?
            <div className="peopleNames">
                {state.people.map(person => <div className="personName">{person.first} {person.last}</div>)}
            </div>
            <div className="RSVPHolder">
              <div className="submit noselect" onClick={handleSubmit2}>Yes</div>
              <div className="submit noselect" onClick={handleNo}>No</div>
            </div>
        </div>
      ) : state.step === 2 ? (
        <div className="step2">
            Please check off the guests who will be attending. 
            <div className="peopleNames">
              {state.people.map((person, index) => {
                  return (
                  <div className="personName">
                      <input type='checkbox' id={`box-${index}`} checked={state.checkedPeople[index]}
                      onChange={() => handleChange(index)}/>
                      <label for={`box-${index}`}>{person.first} {person.last}</label>
                  </div>
                  )
              })}
            </div>
            <div className="submit noselect" onClick={handleSubmit3}>RSVP</div>
            Watch out! You can only RSVP for this group once. If you need to adjust your response, please contact Kristen (516-492-7945).
        </div>
      ) : (
        <div>
            Thank you for RSVPing! With love, Kristen and Michael.
        </div>)
      }
    </div>
  )
};

export default RSVPForm;